export const pr = {
  Beetroot: {
    name: "Beetroot",
    price: 72,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/beetroot.jpg",
    quantity: 0,
  },
  Brinjal: {
    name: "Brinjal",
    price: 54,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/brijal.jpg",
    quantity: 0,
  },
  Cabbage: {
    name: "Cabbage",
    price: 45,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/cabbage.jpg",
    quantity: 0,
  },
  Capsicum: {
    name: "Capsicum",
    price: 32,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/capsicum.jpg",
    quantity: 0,
  },
  Carrot: {
    name: "Carrot",
    price: 25,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/carrot.jpg",
    quantity: 0,
  },
  Cauliflower: {
    name: "Cauliflower",
    price: 68,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/cauliflower.jpg",
    quantity: 0,
  },
  Cucumber: {
    name: "Cucumber",
    price: 37,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/cucmber.jpg",
    quantity: 0,
  },
  Garlic: {
    name: "Garlic",
    price: 59,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/garlic.jpg",
    quantity: 0,
  },
  Ginger: {
    name: "Ginger",
    price: 77,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/ginger.jpg",
    quantity: 0,
  },
  "Lady's Finger": {
    name: "Lady's Finger",
    price: 53,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/ladysfinger.jpg",
    quantity: 0,
  },
  Mushroom: {
    name: "Mushroom",
    price: 84,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/mushroom.jpg",
    quantity: 0,
  },
  Onion: {
    name: "Onion",
    price: 29,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/onion.jpg",
    quantity: 0,
  },
  Peas: {
    name: "Peas",
    price: 62,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/peas.jpg",
    quantity: 0,
  },
  Potato: {
    name: "Potato",
    price: 41,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/potato.jpg",
    quantity: 0,
  },
  Pumpkin: {
    name: "Pumpkin",
    price: 56,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/pumpkin.jpg",
    quantity: 0,
  },
  Radish: {
    name: "Radish",
    price: 38,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/radish.jpg",
    quantity: 0,
  },
  Spinach: {
    name: "Spinach",
    price: 66,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/spinach.jpg",
    quantity: 0,
  },
  Tomato: {
    name: "Tomato",
    price: 27,
    isSelected: false,
    url: "https://storage.googleapis.com/organicveggie/assets/tomato.jpg",
    quantity: 0,
  },
};

export default pr;
